import React from 'react';
import { useTheme } from '../../../utils/context/theme-context';
import { BaseModuleProps } from '../types';
import { convertContentfulVariantToEnum } from '../utils';
import {
    InterlinkingCards,
    InterlinkingCardsProps,
} from './components/InterlinkingCards';

export interface InterlinkingCardsModuleProps extends InterlinkingCardsProps {
    isContentFromContentful?: boolean;
}

export default function InterlinkingCardsModule({
    eyebrow,
    header,
    interlinkingCards,
    isContentFromContentful,
    variant,
}: InterlinkingCardsModuleProps & BaseModuleProps) {
    const theme = useTheme();
    if (isContentFromContentful) {
        variant = convertContentfulVariantToEnum(variant);
    }

    const baseOptions = { variant };
    const [backgroundClass] = theme.generateStyles('background', baseOptions);

    return (
        <section className={backgroundClass}>
            <div className="interlinking-cards-module-container module-landing-page__module-container-content">
                <InterlinkingCards
                    eyebrow={eyebrow}
                    header={header}
                    interlinkingCards={interlinkingCards}
                    variant={variant}
                />
            </div>
        </section>
    );
}
